<template>
  <a-layout style="background: #fff">
    <SearchHeader />
    <div class="container">
      <div
        class="video-cover"
        :style="{ backgroundImage: `url(${videoInfo.cover})` }"
      >
        <div class="video-cover-modal">
          <div class="video-title">{{ videoInfo.title }}</div>
        </div>
      </div>

      <div class="video-info">
        <div class="video-description">{{ videoInfo.description }}</div>
        <div class="video-category">
          <a-tag
            color="#da767b"
            :key="cate"
            v-for="cate in videoInfo.categories"
            >{{ cate }}</a-tag
          >
        </div>
        <div class="video-source">
          来源：<a class="tag" target="_blank" :href="videoInfo.source_url">{{
            utilSources.get(videoInfo.source_url)
          }}</a>
        </div>
      </div>

      <div class="search-result">
        <div class="input-wrap">
          <input
            v-model="searchValue"
            :placeholder="preSearchValue"
            autofocus
            @keyup.enter="handleSearch"
          /><button @click="handleSearch">当前视频搜索</button>
        </div>
        <div class="top-panel">
          <div v-show="searching" style="text-align: center">
            拼命加载中 ...
          </div>
        </div>
        <ul>
          <li v-bind:key="item.id" v-for="item in searchResult">
            <div></div>
            <div>
              <div class="result-timestamp">
                <a
                  target="__blank"
                  class="timestamp"
                  :href="videoInfo.source_url"
                  >{{ item.start_time }}</a
                >
              </div>
              <div class="result-content">
                <span v-html="item.content"></span>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination" v-if="searchResult.length > 0">
          <a-pagination
            v-model="current"
            :total="total"
            @change="handleNextPage"
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </a-layout>
</template>

<script>
import Footer from "@/components/Footer";
import SearchHeader from "@/components/SearchHeader";
import { sources as utilSources } from "@/utils/index";

export default {
  name: "Video",
  props: {
    videoID: String,
  },
  components: {
    SearchHeader,
    Footer,
  },
  data() {
    return {
      utilSources,
      videoInfo: {
        cover:
          "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2999705736,2629890130&fm=26&gp=0.jpg",
      },
      searching: false,
      defaultCover:
        "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2999705736,2629890130&fm=26&gp=0.jpg",
      searchValue: "",
      preSearchValue: "",
      searchResult: [],
      lastedSearch: [],
      current: 1,
      total: null,
    };
  },
  mounted() {
    this.loadVideo();
  },
  watch: {},
  computed: {},
  methods: {
    loadVideo: function () {
      this.$http
        .get("/api/v1/videos/" + this.videoID)
        .catch((e) => {
          this.loading = false;
          this.$error({
            title: "网络错误",
            content: e,
          });
        })
        .then((resp) => {
          this.loading = false;
          this.videoInfo = resp.data.data;
          this.videoInfo.categories = this.videoInfo.category.split("/");
          this.search();
        });
    },
    handleSetSearchValue: function (v) {
      this.searchValue = v;
      this.search(v, 1);
    },
    search: function (word, pageNo) {
      this.preSearchValue = this.searchValue;
      if (!word) {
        this.searchValue = word;
      }
      if (!pageNo) {
        this.current = pageNo;
      }
      this.searching = true;
      this.$http
        .get(`/api/v1/videos/speech/${this.videoID}`, {
          w: this.searchValue,
          offset: (pageNo - 1) * 10,
          limit: 10,
        })
        .catch((e) => {
          this.searching = false;
          this.$error({
            title: "网络错误",
            content: e,
          });
        })
        .then((resp) => {
          this.searching = false;
          let results = resp.data.data.list;
          for (let i = 0; i < results.length; i++) {
            results[i].content = results[i].content.replaceAll(
              this.searchValue,
              `<span class='outstand'>${this.searchValue}</span>`
            );
          }
          this.searchResult = results;
          this.total = resp.data.data.count;
          console.log(resp);
        });
    },
    handleNextPage: function () {
      this.search(this.searchValue, this.current);
    },
    handleSearch: function (e) {
      if (!this.searchValue) return;
      this.search(this.searchValue, 1);
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1000px;
  margin: 0 auto;
}

::-webkit-input-placeholder {
  color: #aaa;
}

.input-wrap {
  display: flex;
  width: 500px;
  margin: 0 auto;
}
.input-wrap input {
  flex: 1;
  color: #333;
  height: 40px;
  border-radius: 8px 0 0 8px;
  border-width: 2px 0 2px 2px;
  outline: none;
  padding: 0 20px;
}

.input-wrap button:hover {
  cursor: pointer;
  background: #000;
}
.input-wrap button {
  height: 40px;
  border-radius: 0 8px 8px 0;
  border-width: 2px 2px 2px 0;
  background: #333;
  color: #fff;
  padding: 0 20px;
  outline: none;
}

.search-result {
  max-width: 1000px;
  margin: 20px auto;
}
.search-result ul {
  margin: 0;
  padding: 0;
}
.search-result li {
  list-style: none;
  display: flex;
  flex-direction: row;
  transition: 500ms;
  margin-top: 20px;
  font-size: 16px;
}

.video-cover {
  width: 1000px;
  height: 400px;
  margin: 10px auto;
  background-image: url(https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2999705736,2629890130&fm=26&gp=0.jpg);
  background-size: cover;
  background-position: center;
}
.timestamp {
  color: #666;
}
.search-result .result-info {
  flex: 1;
}

.video-cover-modal {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  position: relative;
}
.video-title {
  font-size: 20px;
  padding: 20px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.video-info {
  padding: 10px;
}
.video-source {
  margin-top: 10px;
}

.video-category {
  margin-top: 10px;
}
.pagination {
  margin: 10px auto;
  text-align: center;
}

a {
  color: #333;
}

.tag {
  color: #cc3940;
  background: #f3eded;
  border-radius: 2px;
  text-decoration: none;
  padding: 4px 8px;
  margin: 2px 4px;
  display: inline-block;
}

.tag:hover {
  color: #f3eded;
  background: #cc3940;
}
.top-panel {
  height: 40px;
}

.page-title {
  padding-left: 4px;
  border-left: 4px solid #cc3940;
}

@media screen and (max-width: 600px) {
  .container {
    width: auto;
    margin: 0 auto;
  }

  .input-wrap {
    display: flex;
    margin: 0 auto;
    width: auto;
    padding: 0 1em;
    font-size: 18px;
  }
  .input-wrap input {
    flex: 1;
    color: #333;
    height: 2em;
    width: auto;
    border-radius: 8px 0 0 8px;
    border-width: 2px 0 2px 2px;
    outline: none;
    padding: 0 0.5em;
  }

  .input-wrap button:hover {
    cursor: pointer;
    background: #000;
  }
  .input-wrap button {
    height: 2em;
    border-radius: 0 8px 8px 0;
    border-width: 2px 2px 2px 0;
    background: #333;
    color: #fff;
    padding: 0 0.5em;
    outline: none;
  }

  .search-result {
    width: auto;
    margin: 10px;
  }
  .search-result ul {
    margin: 0;
    padding: 0;
  }
  .search-result li {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .video-cover {
    width: 100%;
    height: 200px;
    margin: 10px auto;
  }
}
</style>
