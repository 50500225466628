<template>
  <a-layout style="background:#FFF;">
    <div class="page-header" >
      <a href="/">
        <div class="logo-text">
        知视<span class="logo-bg">搜索</span></div>
      </a>
      <div class="input-wrap">
        <input v-model="searchValue" :placeholder="'李诞'" @keyup.enter="handleSearch"  /><button  @click="handleSearch" >🔍</button>
      </div>
    </div>
  </a-layout>
</template>

<script>
  


export default {
  name: 'SearchHeader',
  props: {},
  components: {
   
  },
  data() {
    return {
      searchValue: "",
      current: 1,
      total: 0,
      
    };
  },
  mounted(){
  },
  watch:{
  },
  computed: {
    
    
  },
  methods: {
    handleSearch: function(e)  {
      window.open(`/?w=${this.searchValue}`)
    }
  }
}
</script>

<style scoped lang="less">
.page-header {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.logo-text {
  font-size: 20px;
  text-align: center;
  padding: 10px 8px;
}
.logo-bg {
  color: #FFF;
  border-radius: 4px;
  background: #ED1B25;
  padding: 3px;
  margin-left: 4px;
  
}

::-webkit-input-placeholder {
  color: #aaa;
}

.input-wrap {
  display: flex;
  width: 200px;
}
.input-wrap input {
  flex: 1;
  color:#333;
  height: 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
  outline: none;
  padding: 0 10px;
}

.input-wrap button:hover {
  cursor: pointer;
  background: #000;
}
.input-wrap button {
  height: 30px;
  border-radius: 0 4px 4px 0;
  border-width: 1px 1px 1px 0;
  background: #333;
  color: #FFF;
  padding: 0 10px;
  width: 80px;
  outline: none;
}

.last-updated {
  width: 500px;
  margin: 0 auto;
  margin-top: 20px;
}

a {
  color: #333;
}
@media screen and ( max-width: 600px ) {


}
</style>
